.header {
  margin: 50px 0;

  .logo {
    max-width: 120px;
    display: block;
    margin: auto;
  }
  
  .tagline {
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;

    &.invert {
      color: #fff;
    }
  }

}
