.whiteboardBrushSize {
  display: flex;
  align-items: center;
  width: 100%;

  .size {
    margin-right: 7px;
    font-size: 0.95em;
  }

  .sizeInput {
    -webkit-appearance: none;
    width: 100%;
    height: 7px;
    background: #DEDEDE;
    border-radius: 3px;
    
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      border: none;
      background: var(--ion-color-primary);
      cursor: pointer;
    }
    
    &::-moz-range-thumb {
      -webkit-appearance: none;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      background: var(--ion-color-primary);
      border: none;
      cursor: pointer;
    }
    
    &:focus {
      outline: none;
    }
  }
}
