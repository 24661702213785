.notFound {
  text-align: center;

  .heading {
    font-size: 4.2em;
    margin: 0;
  }

  .description {
    margin-top: 0;
    margin-bottom: 40px;
  }
}
